.react-autosuggest__container {
  display: inline-block;
}

.react-autosuggest__suggestions-container {
  position: absolute;
  z-index: 100;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
